import { render, staticRenderFns } from "./Annotations.vue?vue&type=template&id=04e65ab8&scoped=true&"
import script from "./Annotations.vue?vue&type=script&lang=ts&"
export * from "./Annotations.vue?vue&type=script&lang=ts&"
import style0 from "./Annotations.scss?vue&type=style&index=0&id=04e65ab8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e65ab8",
  null
  
)

export default component.exports