































































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import { VueEditor } from 'vue2-editor';

import moment from 'moment';

import ErrorLoading from '@/components/ErrorLoading/ErrorLoading.vue';

import BookService from '@/services/Book/BookService';

import CommentsOrAnnotationsLoading from '../CommentsOrAnnotationsLoading/CommentsOrAnnotationsLoading.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

interface InterfaceComments {
  _id: string | null;
  content: string | null;
  order: number | null;
  createdAt: Date | null;
  updatedAt: Date | null;
}

const SUBJECT_NAME_ARCHIVES = 'archives';
const PAGE_NUMBER_TEN = 10;
const DIGIT_ZERO = '0';

@Component({
  components: {
    VueEditor,
    CommentsOrAnnotationsLoading,
    ErrorLoading
  }
})
export default class Annotations extends Vue {
  @Prop() subject!: string;
  @Prop() fileID!: string;
  @Prop() order!: Record<string, any>;
  @Prop() timeVideo!: number;
  @Prop() loading!: boolean;

  private listAnnotations: Array<Record<string, any>> = [];
  private isLoadingAnnotations = true;
  private errorLoading = false;
  private content = '';

  private bookService = new BookService();

  private customToolbar = [
    'bold',
    'italic',
    'link',
    { list: 'ordered' },
    { list: 'bullet' },
    'blockquote'
  ];

  mounted() {
    this.getAnnotations();
  }

  get getTime() {
    const currentPositionFile = this.order?.order;

    if (this.subject === SUBJECT_NAME_ARCHIVES && currentPositionFile) {
      return this.adjustAnnotationFormat(currentPositionFile);
    }

    const formatted = moment.utc(this.order?.time * 1000).format('mm:ss');

    return formatted;
  }

  @Watch('subject')
  @Watch('fileID')
  async getAnnotations() {
    try {
      this.isLoadingAnnotations = true;

      if (this.subject && this.fileID) {
        const annotations = await this.bookService.getAnnotations(this.subject, this.fileID);

        if (annotations && annotations?.annotations.length) {
          this.listAnnotations = this.sortAnnotations(annotations.annotations);
        } else this.listAnnotations = [];
      }
    } catch (error) {
      this.errorLoading = true;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as anotações.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingAnnotations = false;
    }
  }

  sortAnnotations(listAnnotations: Array<Record<string, any>>) {
    const annotations = listAnnotations.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    return annotations;
  }

  setTime(order: number) {
    if (this.subject === SUBJECT_NAME_ARCHIVES) return this.adjustAnnotationFormat(order);

    const time = (this.timeVideo * order) / 100;
    const timeFormatted = moment.utc(time * 1000).format('mm:ss');
    return timeFormatted;
  }

  adjustAnnotationFormat(currentPosition: number) {
    return `Pág. ${currentPosition < PAGE_NUMBER_TEN ? DIGIT_ZERO : ''}${currentPosition}`;
  }

  async setAnnotation(annotation: string) {
    try {
      if (!this.content) {
        return;
      }

      this.setNewAnnotation(annotation);

      this.content = '';

      await this.bookService.postAnnotations(
        this.subject,
        this.fileID,
        annotation,
        this.order?.order || 0
      );

      this.setTrackAmplitude();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar as anotações.',
        status: 'error'
      });
      console.error(error);
    }
  }

  setNewAnnotation(annotation: string) {
    const newAnnotation: InterfaceComments = {
      _id: null,
      content: annotation,
      order: this.order?.order,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    this.listAnnotations.push(newAnnotation);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Annotations',
          local: 'Anotar'
        }
      }
    });
  }
}
